(function () {
  'use strict';

  angular.module('app.layout').directive('scHeaderTab', scHeaderTabDirective);

  function scHeaderTabDirective($state, Tabs) {
    return {
      restrict: 'A',
      require: '^mdTabs',

      link: function ($scope, $element, $attr, $mdTabs) {
        subscribeToMdTabsChanges();
        checkIfActiveStateMatch();
        $scope.$on('$stateChangeSuccess', checkIfActiveStateMatch);
        $scope.$on('$mdTabs:modified', checkIfActiveStateMatch);

        function checkIfActiveStateMatch() {
          const isStateMatched = $state.is(getStateName());
          const isParamsMatched = angular.equals($state.params, getStateParams());
          const index = $mdTabs.getTabElementIndex($element);

          if (isStateMatched && isParamsMatched && $mdTabs.selectedIndex !== index) {
            $mdTabs.select(index);
          }
        }

        function subscribeToMdTabsChanges() {
          $mdTabs.selectSubscribers = $mdTabs.selectSubscribers || [];
          $mdTabs.selectSubscribers.push(function (tabIndex) {
            if ($mdTabs.getTabElementIndex($element) === tabIndex) {
              Tabs.setActive(getTabInstance());
            }
          });
        }

        function getTabInstance() {
          return $scope.$eval($attr.scHeaderTab);
        }

        function getStateName() {
          return getTabInstance().state;
        }

        function getStateParams() {
          return getTabInstance().params;
        }
      },
    };
  }
})();
